import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import Swiper from 'react-id-swiper'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

/* COMPONENTS */
import Layout from '../components/Layout/index'
// import BannerDiaExpress from '../components/Diaexpress/banner-dia-express'

/* CSS e SCSS */
import './styles/clubdia.scss'

/* Imagens SVG */
import BlackAppleStore from '../images/app/app-store.svg'
import BlackGooglePlay from '../images/app/google-play.svg'
import imgAproveite from '../images/app/aproveite.svg'
import imgBaixe from '../images/app/baixe.svg'
import imgCadastre from '../images/app/cadastre.svg'
import imgResgate from '../images/app/imgResgate.png'
import arrow from '../images/app/arrow.svg'

/* Dark Mode */
import arrowhite from '../images/app/arrowhite.svg'

const swiperParams = {
  spaceBetween: 1,
  slidesPerView: 1.1,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true
  },
  breakpoints: {
    550: {
      slidesPerView: 'auto',
      spaceBetween: 0
    },
    900: {
      slidesPerView: 'auto',
      spaceBetween: 0
    },
    1000: {
      slidesPerView: 'auto',
      spaceBetween: 0
    },
    1100: {
      slidesPerView: 'auto',
      spaceBetween: 0
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 0
    }
  }
}
const swiperParamsPassos = {
  slidesPerView: 1,
  spaceBetween: 1,
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true
  },
  breakpoints: {
    550: {
      slidesPerView: 1,
      spaceBetween: 1
    },
    900: {
      slidesPerView: 1,
      spaceBetween: 1
    },
    1000: {
      slidesPerView: 4,
      spaceBetween: 1
    },
    1100: {
      slidesPerView: 4,
      spaceBetween: 1
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 1
    }
  }
}

const MoldeCard = props => (
  <div className="col-auto d-flex justify-content-center">
    <div className="card card-vantagens">
      <div className="card-header p-0 position-relative d-flex justify-content-center align-items-end">
        <div className="position-relative w-100 above">
          <GatsbyImage image={props.image} alt="Imagem Card Celular" className="w-100" />
        </div>
      </div>
      <div className="card-body text-center">
        <p className="card-title text-red font-Lato-Bold mb-2 font-size-13">{props.title}</p>
        <p className="card-text font-size-1">{props.text}</p>
      </div>
    </div>
  </div>
)

const DuvidasFrequentes = props => (
  <div className="tab unselected">
    <input id={props.DuvidaNumber} type="radio" name="duvidas"/>
    <label className="titleCollapse font-size-1" htmlFor={props.for}> {props.duvidaTitle}
      <img className="arrowDuvidas lightMode" src={arrow} alt='icone seta' />
      <img className="arrowDuvidas darkMode" src={arrowhite} alt='icone seta' />
    </label>
    <div className="tab-content">
      <div className="row">
        <div className="col-md-11 mx-auto font-size-1">
          {props.answer}
        </div>
      </div>
    </div>
  </div>
)

const Passos = props => (
  <div className="col-lg-12 col-10 text-center pb-lg-5 position-relative">
    <div className="row justify-content-left position-absolute">
      <GatsbyImage image={props.image} alt='Numero' className="ml-lg-0 ml-5 w-lg-75" style={{ zIndex: '-1' } }/>
    </div>
    <div className="row justify-content-center pt-5">
      <div className="divhero ml-lg-0 ml-5">
        <div className="w-100 p-0 m-0 justify-content-center">
          <img src={props.icon} alt="Icone" />
        </div>
        <p className="title-passosApp text-center m-0 mt-2 mb-4 text-white font-size-15">{props.title}</p>
        <p className="pr-lg-3 pl-lg-4 text-center text-white font-size-1">{props.text}</p>
      </div>
    </div>
  </div>
)

export default function App (props) {
  const [verMais, setVerMais] = useState(false)
  const breadC = {
    visible: true,
    color: 'white',
    items: [
      { path: '/', name: 'Home' },
      { path: '/clubdia/', name: 'ClubDia' }
    ]
  }
  return (
    <Layout breadCrumb={breadC}>
      <Helmet>
        <html lang="pt-BR" amp />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <title>App ClubDIA - Dia Supermercados</title>
        <meta name="description" content="Só quem tem o app ClubDIA tem acesso a descontos exclusivos para pagar ainda menos no DIA. Baixe agora!" />
        <meta name="keywords" content="clubdia, app desconto, desconto clubdia" />
        <meta name="author" content="DIA Supermercados" />
      </Helmet>
      <GatsbyImage
        image={props.data.bannerClubDIA.childImageSharp.gatsbyImageData}
        className='d-md-block d-none'
      />
      <GatsbyImage
        image={props.data.bannerMobileClubDIA.childImageSharp.gatsbyImageData}
        className='d-md-none d-block'
      />
      <section>
        <div className="container my-5">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <div className="row">
                {/* <div className="col-lg-5 topo">
                  <h1 tabIndex={0}>2 Milhões</h1>
                  <h3 tabIndex={0}>de downloads</h3>
                </div> */}
                <div className='col-lg-7 justify-content-start align-self-center'>
                  <h4 className='font-Lato-Bold'>Só quem tem o app ClubDIA tem acesso a descontos exclusivos para pagar ainda menos no DIA. Baixe agora!</h4>
                </div>
                <div className="col-lg-2 col-5 ml-3">
                  <a href="https://play.google.com/store/apps/details?id=br.com.dia.customers&referrer=utm_source%3Dsite%26utm_medium%3Dbutton%26utm_campaign%3Ddownloadapp" target="_blank" rel="noopener noreferrer"><img src={BlackGooglePlay} alt="Botão de download do aplicativo do Dia para Android" width={152} height={62} className="w-100"/></a>
                </div>
                <div className="col-lg-2 col-5">
                  <a href="https://click.google-analytics.com/redirect?tid=UA-26046188-5&url=https%3A%2F%2Fitunes.apple.com%2Fus%2Fapp%2Fmeu-desconto-clubdia%2Fid1311617705%3Fmt%3D8&aid=br.com.dia.customers&idfa=%{idfa}&cs=site&cm=button&cn=downloadapp" target="_blank" rel="noopener noreferrer" ><img src={BlackAppleStore} alt="Botão de download do aplicativo do Dia para IOS" width={152} height={62} className="w-100"/></a>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
        <div className="container">
          <div id="swiperCards">
            <h2 tabIndex={0} className="text-red text-center font-Lato-Bold">Vantagens que só os clientes ClubDIA têm</h2>
            <Swiper {...swiperParams}>
              <div className='mb-5'>
                <MoldeCard image={props.data.imgCupons.childImageSharp.gatsbyImageData} title="Meus Cupons" text={ <> Com os cupons ClubDIA você economiza muito mais. É só escolher o cupom, ativá-lo, informar o CPF cadastrado no caixa e aproveitar! </> } />
              </div>
              <div>
                <MoldeCard image={props.data.imgEconomias.childImageSharp.gatsbyImageData} title="Minhas Economias" text={ <> Quer saber o quanto você economizou comprando no DIA? A gente te mostra de forma rápida e <br/>fácil. </> } />
              </div>
              <div>
                <MoldeCard image={props.data.imgOfertas.childImageSharp.gatsbyImageData} title="Ofertas da Semana" text={ <> Além dos cupons de desconto, clientes ClubDIA ainda aproveitam ofertas imperdíveis toda <br/>semana. </> } />
              </div>
              <div>
                <MoldeCard image={props.data.imgLojas.childImageSharp.gatsbyImageData} title="Lojas Próximas" text={ <> Quer ver se tem uma loja DIA perto de você? Faça uma busca e confira as mais de 600 lojas espalhadas pelo país. </> } />
              </div>
            </Swiper>
          </div>
        </div>
        <div className="bg-dia-red-3 w-100" id="vejaFacil">
          <div className="container mb-2">
            <div className="col-12 text-center pt-5 pb-4">
              <h3 tabIndex={0} className="text-center text-white font-Lato-Bold">4 passos para ativar cupons e economizar mais</h3>
            </div>
            <div className="col-lg-12 p-0 m-0">
              <div className="justify-content-center" id="swiperPassos">
                <Swiper {...swiperParamsPassos}>
                  <div>
                    <Passos image={props.data.num1.childImageSharp.gatsbyImageData} icon={imgBaixe} title={<>Baixe o APP<br/> no seu celular</>} text={<> Procure por ClubDIA na Play Store ou Apple Store. </> } />
                  </div>
                  <div>
                    <Passos image={props.data.num2.childImageSharp.gatsbyImageData} icon={imgCadastre} title={<>Cadastre<br/> seus dados</>} text={<> Clique em &quot;login&quot;, depois em &quot;Cadastre-se&quot;, informe seu e-mail e senha e atualize seus dados pessoais. </> } />
                  </div>
                  <div>
                    <Passos image={props.data.num3.childImageSharp.gatsbyImageData} icon={imgResgate} title={<>Regaste<br/> seus cupons</>} text={<> Acesse o menu &quot;Meus Cupons&quot;, escolha o seu estado e resgate seus cupons na hora! Não precisa esperar! </> } />
                  </div>
                  <div>
                    <Passos image={props.data.num4.childImageSharp.gatsbyImageData} icon={imgAproveite} title={<>Aproveite os<br/> descontos</>} text={<> Vá até uma loja mais perto de você e informe seu CPF, o mesmo do cadastro do APP. Seus descontos serão conferidos automaticamente. </> } />
                  </div>
                </Swiper>
              </div>
            </div>
            <div className="row justify-content-center">
              <p className="text-lg-center col-lg-7 mb-5 text-white font-size-1">*Os cupons de desconto disponibilizados no APP são válidos em todas as lojas DIA. Você só precisa selecionar o estado em que se encontra para consultar os cupons disponíveis a você!</p>
            </div>
          </div>
        </div>
        <section id="duvidas-frequentes">
          <div className="container pt-5">
            <h2 tabIndex={0} className="text-center text-red mb-5 font-Lato-Bold">Tire suas dúvidas sobre o ClubDIA</h2>
            <div className="row d-flex">
              <div className="col-lg-6">
                <DuvidasFrequentes
                  DuvidaNumber = "one"
                  for = "one"
                  duvidaTitle = "01. O app está disponível em quais sistemas operacionais?"
                  answer = { <> ClubDIA está disponível na:<br/>
                    - versão 7.0 ou superior de Android<br/>
                    - versão 12.0 ou superior de iOS<br/><br/>
                    Acesse a Play Store ou Apple Store e procure <b>&quot;ClubDIA&quot;</b>. Em seguida, clique em instalar e aceitar as permissões necessárias.<br/><br/>
                    Prontinho. Agora é só aproveitar os descontos do nosso app! 😃 </>}
                />
                <DuvidasFrequentes
                  DuvidaNumber = "two"
                  for = "two"
                  duvidaTitle = "02. O que preciso para me cadastrar"
                  answer = { <> É supersimples se cadastrar no ClubDIA. 😃<br/><br/>
                    Selecione um e-mail válido e crie uma senha com oito caracteres no próprio app. Após isso, um e-mail será enviado na caixa de entrada ou spam. Clicando no link recebido, você estará confirmando seu cadastro. É só preencher as informações solicitadas e pronto. Você é o mais novo cliente do ClubDIA! 👏👏👏 </>}
                />
                <DuvidasFrequentes
                  DuvidaNumber = "three"
                  for = "three"
                  duvidaTitle = "03. Esqueci a senha. Como posso recuperá-la?"
                  answer = { <> Fique tranquilo. Na tela de login, clique na opção <b>&quot;Esqueci Minha Senha&quot;</b> e automaticamente um e-mail será enviado para você definir sua nova senha. Aí é só aproveitar os cupons de desconto! 😉 </>}
                />
                <DuvidasFrequentes
                  DuvidaNumber = "four"
                  for = "four"
                  duvidaTitle = "04. Esqueci meu e-mail. Como posso recuperá-lo?"
                  answer = { <> Na tela de login, clique na opção <b>&quot;Esqueci Meu E-mail&quot;</b> e depois digite seu CPF, em seguida, você irá receber uma mensagem de recuperação. Fácil, né? </>}
                />
                <DuvidasFrequentes
                  DuvidaNumber = "five"
                  for = "five"
                  duvidaTitle = "05. Não tenho mais acesso ao e-mail cadastrado. Como posso alterá-lo?"
                  answer = { <> Clique na opção <b>&quot;Meu ClubDIA&quot;</b>, em seguida <b>&quot;Alterar e-mail&quot;</b> e pronto! Caso não tenha mais acesso ao app, entre em contato com o SAC pelo 📞 <b>0800 723 1470</b> ou pelo e-mail: <b>sac@dia.com.br</b>. </>}
                />
                <div className={`d-lg-block d-${verMais === false ? 'none' : 'block'}`}>
                  <DuvidasFrequentes
                    DuvidaNumber = "six"
                    for = "six"
                    duvidaTitle = "06. Já sou cliente ClubDIA, o que preciso fazer para usar o app?"
                    answer = { <> Acesse o app e realize seu cadastro. Em seguida, é só aproveitar! 😎 </>}
                  />
                  <DuvidasFrequentes
                    DuvidaNumber = "seven"
                    for = "seven"
                    duvidaTitle = "07. Como faço para usar os cupons?"
                    answer = { <> Entre no app e clique em <b>&quot;Meus Cupons&quot;</b>, selecione o seu estado (SP ou MG) e escolha os cupons que irá utilizar. Quando ativar o cupom, você precisa apenas se identificar no caixa informando seu CPF. Só cliente ClubDIA tem descontos exclusivos! 🛒🛒🛒 </>}
                  />
                  <DuvidasFrequentes
                    DuvidaNumber = "eight"
                    for = "eight"
                    duvidaTitle = "08.  Como faço para ativar meus cupons?"
                    answer = { <> Faça login, em seguida clique no ícone <b>&quot;Meus Cupons&quot;</b>, escolha o estado em que se encontra (SP ou MG) e selecione os cupons que você quer utilizar. Uma vez ativado o cupom no app, você precisará apenas se identificar no caixa da loja informando o seu CPF. Pronto! Agora é só usar. </>}
                  />
                  <DuvidasFrequentes
                    DuvidaNumber = "nine"
                    for = "nine"
                    duvidaTitle = "09. Posso confirmar um cupom e usar na mesma hora?"
                    answer = { <> Pode, sim! 😀 O cupom precisa apenas ser ativado antes de passar as compras no caixa. Não perca tempo! </>}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <DuvidasFrequentes
                  DuvidaNumber = "ten"
                  for = "ten"
                  duvidaTitle = "10. Quantas vezes posso usar cada cupom de desconto e qual a validade dele?"
                  answer = { <> Cada cupom tem sua regra de utilização e validade. Para verificar essas informações, clique no cupom e veja os detalhes. 😀 </>}
                />
                <div className={`d-lg-block d-${verMais === false ? 'none' : 'block'}`}>
                  <DuvidasFrequentes
                    DuvidaNumber = "eleven"
                    for = "eleven"
                    duvidaTitle = "11. Terei desconto se a loja estiver sem sistema?"
                    answer = { <> Infelizmente sem sistema não temos como dar o desconto. A loja precisa de conexão com o sistema para aplicar o cupom em suas compras. Você pode esperar um pouquinho ou voltar outra hora. </>}
                  />
                  <DuvidasFrequentes
                    DuvidaNumber = "twelve"
                    for = "twelve"
                    duvidaTitle = "12. Como faço para saber quais são os produtos em oferta?"
                    answer = { <> Um jeito fácil de receber nossas ofertas é chamar a gente no ZAP: 📞 <b>11-95311-8685</b>. Se preferir, também pode <Link to='https://www.dia.com.br/' className='text-red text-underline'>acessar nosso site</Link> e clicar na aba <b>&quot;Ofertas da semana&quot;</b>.<br/><br/> Boas compras e ótima economia! </>}
                  />
                  <DuvidasFrequentes
                    DuvidaNumber = "thirteen"
                    for = "thirteen"
                    duvidaTitle = "13. Posso consultar o quanto comprei e economizei?"
                    answer = { <> Sim! Em <b>&quot;Minhas Economias&quot;</b> você encontra o histórico mensal/anual do valor das compras e da economia gerada com o ClubDIA. 🤑 Novas compras vão aparecer no histórico do app em até 24h após realizadas. </>}
                  />
                  <DuvidasFrequentes
                    DuvidaNumber = "fourteen"
                    for = "fourteen"
                    duvidaTitle = "14. Posso parcelar minha compra?"
                    answer = { <> Pagar barato e ainda parcelar suas compras? No DIA você pode! 😊 Mas se liga: o parcelamento pode ser feito em compras acima de <b>R$ 90</b> em todas as lojas. 💳 Ah, não se esqueça de ver as bandeiras aceitas no caixa e a quantidade de parcelas desejadas. </>}
                  />
                  <DuvidasFrequentes
                    DuvidaNumber = "fifteen"
                    for = "fifteen"
                    duvidaTitle = "15. Qual o telefone da loja?"
                    answer = { <> Não temos telefone nas lojas, mas você pode acessar o Fale Conosco através do link <Link to='https://www.dia.com.br/contato/' className='text-red text-underline'>https://www.dia.com.br/contato/</Link> 🗣️<br/><br/>E não se esqueça: tem sempre um DIA pertinho de você. <Link to='https://www.dia.com.br/lojas/' className='text-red text-underline'>Clique aqui</Link> e confira a loja mais perto da sua casa. 😊 </>}
                  />
                  <DuvidasFrequentes
                    DuvidaNumber = "sixteen"
                    for = "sixteen"
                    duvidaTitle = "16. Desconto não aplicou na minha compra."
                    answer = { <> Nós vamos ajudá-lo. Você apenas precisa responder algumas perguntas:<br/><br/>
                      1 – O cupom foi selecionado antes de iniciar a compra no caixa?<br/>
                      2 - Na nota fiscal está o mesmo número de ClubDIA que consta em seu app?<br/>
                      3 - O cupom estava dentro da validade de uso?<br/><br/>
                      Caso você tenha feito tudo certo e continua sem o desconto, fale com a gente! 🗣️<br/>
                      É só entrar em contato com nosso canal de relacionamento:<br/>
                      <Link to='https://www.dia.com.br/contato/' className='text-red text-underline'>https://www.dia.com.br/contato/</Link> </>}
                  />
                  {/* <DuvidasFrequentes
                    DuvidaNumber = "seventeen"
                    for = "seventeen"
                    duvidaTitle = "17. Como funciona a entrega a domicílio?"
                    answer = { <> Economizar muito e receber tudo em casa? Isso é DIA! Fazendo suas compras pelo <Link to='https://api.whatsapp.com/send/?phone=5511994296470&text&type=phone_number&app_absent=0' target="_blank" className='text-red text-underline'>WhatsApp Dia Express</Link> ou com um de nossos parceiros Rappi, iFood ou Cornershop, suas compras chegam em instantes no conforto de sua casa! 🏡<br /><br /> <Link to='/delivery/' className='text-red text-underline'>Saiba mais sobre esse app que vai facilitar e muito a sua vida!</Link> </>}
                  /> */}
                  {/* <DuvidasFrequentes
                    DuvidaNumber = "eighteen"
                    for = "eighteen"
                    duvidaTitle = "18. Fiz uma compra no DIA Express e ainda não chegou."
                    answer = { <> Fique tranquilo. Vamos verificar o que aconteceu. Por favor, entre em contato com a central responsável <a href='mailto:suporte@diaexpress.com.br' className='text-red text-underline'>suporte@diaexpress.com.br</a> e informe o ocorrido. </>}
                  />
                  <DuvidasFrequentes
                    DuvidaNumber = "nineteen"
                    for = "nineteen"
                    duvidaTitle = "19. Sou colaborador do DIA e não recebi o meu cupom. O que posso fazer?"
                    answer = { <> Colaborador do DIA, vamos resolver isso para você. Mande um e-mail para <a href='mailto:sac@dia.com.br' className='text-red text-underline'>sac@dia.com.br</a> com todas as informações do ocorrido. Atendimento de segunda a sexta-feira, das 9h às 18h. </>}
                  /> */}
                </div>
              </div>
              <div className="col-12 mt-2 d-lg-none d-block text-center">
                <p className="mb-2">Mostrando {verMais === false ? '5' : '18'} de 18</p>
                <div className="row justify-content-center text-center">
                  {verMais === false
                    ? <button type="button" onClick={() => setVerMais(true)} className="btn btn-water-green col-8 w-100 text-white">Ver todas as dúvidas</button>
                    : <button type="button" onClick={() => setVerMais(false)} className="btn btn-water-green col-8 w-100 text-white">Esconder dúvidas</button> }
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <div className='mb-5'>
          <BannerDiaExpress banner={props.data.bannerWppDia} bannerMobile={props.data.bannerWppDiaMobile} />
        </div> */}
      </section>
    </Layout>
  )
}

export const query = graphql`{
  bannerClubDIA: file(relativePath: {eq: "app/banner-clubdia-new.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  bannerMobileClubDIA: file(relativePath: {eq: "app/banner-clubdia-mobile-new.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  imgCupons: file(relativePath: {eq: "app/cupons.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  imgEconomias: file(relativePath: {eq: "app/economias.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  imgOfertas: file(relativePath: {eq: "app/ofertas.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  imgLojas: file(relativePath: {eq: "app/lojas.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  num1: file(relativePath: {eq: "app/1.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 109, height: 180, layout: CONSTRAINED)
    }
  }
  num2: file(relativePath: {eq: "app/2.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 109, height: 180, layout: CONSTRAINED)
    }
  }
  num3: file(relativePath: {eq: "app/3.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 109, height: 180, layout: CONSTRAINED)
    }
  }
  num4: file(relativePath: {eq: "app/4.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 109, height: 180, layout: CONSTRAINED)
    }
  }
  bannerWppDia: file(relativePath: {eq: "home/banner-desktop-whatsapp.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  bannerWppDiaMobile: file(
    relativePath: {eq: "home/banner-mobile-whatsapp.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`
